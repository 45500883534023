<template>
  <v-alert
    text
    color="primary"
  >
    <h3 class="text-h5">
      {{ $t('dtouch.dishOrders.points.title', locale) }}
    </h3>
    <div>
    </div>
    <v-divider
      class="my-4 info"
      style="opacity: 0.22"
    />

    <v-row
      align="center"
      no-gutters
    >
      <v-col>
        <v-btn
          color="primary"
          outlined
          @click="onAdd"
        >
        {{ $t('common.create', locale) }} {{ $t('dtouch.dishOrders.points.point', locale) }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>
<script>
export default {
  props: {
    locale: {
      type: String,
      default: null,
    },
    onAdd: {
      type: Function,
      required: true,
    },
  },
}
</script>

